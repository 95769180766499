.creation-modal {
  width: 70vw;
  min-width: 500px;
  
  .creation-body {
    justify-content: center;
  }

  .creation-footer {  
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
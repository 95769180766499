.annotators-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 220px));  
    align-items: start;
    gap: 15px;
    width: 100%;
    height: 100%;
    padding: 10px;

    @media screen and (max-width: 768px) {
        justify-content: center;
    }
}

.annotator-card {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    height: 320px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    position: relative;
    overflow: hidden; /* Ensure content stays within bounds */

    .annotator-utils-container {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 12px;
        left: 0;
        top: 0;
        opacity: 0;
        transition: opacity 0.2s ease-in-out;

        &:hover {
            cursor: pointer;
            opacity: 1;
        }

        .util-buttons {
            display: flex;
            justify-content: flex-end;
            padding: 10px;
            gap: 8px;

            button {
                background-color: #ffffff;
                border: 1px solid #A3F5EA;      
                cursor: pointer; 
                border-radius: 6px;  
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out;

                &:hover {
                    background-color: #e0f7fa;
                    transform: scale(1.02);
                }
            }

            img {
                max-width: 16px;
            }
        }
    }

    .user-avatar-cntainer {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            max-width: 80px;
        }
    }

    .user-info-container {
        display: flex;
        flex-direction: column;
        flex: 2;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding-bottom: 10px;

        .username {
            font-weight: bold;
            font-size: 20px;
            margin-bottom: 5px;
        }

        .user-role-container {
            font-size: 14px;
            margin-bottom: 10px;
            padding: 2px;
        }

        .user-projects-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
        
            .projects-list {
                display: flex;
                gap: 5px;
                width: 100%;
                overflow-x: auto;
                justify-content: center;
                padding: 5px;
        
                .list-item {
                    border-radius: 50%;
                    height: 30px;
                    aspect-ratio: 1/1;
                    display: flex;
                    color: white;
                    background-color: blue;
                    justify-content: center;
                    align-items: center;
                    z-index: 20;
                    transition: transform 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        
                    &:hover {
                        cursor: pointer;
                        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 8px;
                        transform: scale(1.05);
                    }
                }
            }
        }
    }

    .update-btn-container {
        display: flex;
        flex: 1;
        width: 100%;
        margin-top: auto; 
        justify-content: center;
        align-items: center;
        gap: 5px;

        button {
            font-size: 12px;
            padding: 5px 10px;
            border-radius: 6px;
            cursor: pointer;
            transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out;
            z-index: 20;

            &:hover {
                background-color: #f1f1f1;
            }
        }

        button:first-child {
            background-color: #f44336;
            color: white;
            border: none;

            &:hover {
                background-color: #e53935;
            }
        }

        button:last-child {
            background-color: #4CAF50;
            color: white;
            border: none;

            &:hover {
                background-color: #43A047;
            }
        } 

        button:disabled {
            background-color: #e0e0e0e0;
            color: #666666;
            cursor: not-allowed;

            &:hover{
                background-color: #e0e0e0e0;
            }
        }
    }
}


.dataset-cards-container {
  display: flex;
  justify-content: center;
  align-items: center;

  .dataset-cards-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 200px));  
    align-items: start;
    gap: 5px;
    width: 100%;
    height: 100%;
  
    & .dataset-card {
      aspect-ratio: 1/1;
      display: flex;
      flex-direction: column;
      padding: 16px 16px;
  
      & .card-fields-group {
        display: flex;
        flex-direction: column;
  
        & .card-field {
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 100%;
          overflow-y: auto;
          overflow-x: hidden;
          margin: 0;
  
          &:hover {
            cursor: pointer;
          }
        }
  
        & .card-title {
          font-weight: 500;
        }
  
        & .card-date {
          color:rgb(176, 176, 176);
          font-size: 12px;
        }
      }
  
      & .card-icon-container {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
  
        .card-icon {
          max-width: 70%;
        }
      }
    }
  }  
}

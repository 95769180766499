.statistics-modal {
  width: 95vw !important;
  /* padding: 20px; */
  box-sizing: border-box;
  max-height: 90vh;
  height: 90vh;
  overflow: hidden;

}

.statistics-body{
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
}

.agreement-stat-block{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  .metric-value-evaluation{
    display: flex;
    align-items: center;
    gap: 10px;

    .evaluation-circle{
      border-radius: 100%;
      width: 10px;
      height: 10px;
      display: flex;
  
      &:hover{
        cursor: pointer;
      }
    }
  }

  .info-icon{    
    margin-left: 10px;
    img{
      max-width: 15px;

      &:hover{
        cursor: pointer;
      }
    }
  }
}

.data-chunk-name-container{
  display: flex;
  justify-content: space-between;
  
  .data-chunk-name {
    font-weight: 800;
    font-size: 18px;
  }
}

.data-chunk-statistics-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.progress-bar{
  height: 10px;
  border-radius: 5px;
  width: 75%;
  position: relative;
  background-color: white;
  border: 1px solid #C8F9F2;

  .completion-bar{
    position: absolute;
    height: 100%;
    background-color: #FFA21F;
    border-radius: 5px;
  }
}

.outlier-detection-utils {
  display: flex;
  align-items: center; /* Center children vertically */
  margin: 0px;
  padding: 10px;
  min-width: 500px;

  label {
    margin-left: 10px; 
    display: flex;
    align-items: center; 
  
    input {
      margin: 0px;
    }
  }
}

.update-statistics-btn, .exclude-outlier-btn {
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 5px;
  margin-left: 10px;

  &:hover {
    text-decoration: underline;
  }
}


.data-chunk-stats {
  display: flex;
  flex-direction: column;
  padding: 5px 15px;

  .stats-row{
    display: flex;
    gap: 2vw;
    box-sizing: border-box;
    padding: 5px 15px;
    overflow: auto;
    width: 100%;
    height: 30vh;
    min-height: 150px;
  }

  .pairwise-agreement-row{
    height: 65vh;
  }

  .stats-block {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    min-width: 200px;
    padding: 15px 20px;
    box-shadow: rgba(17, 17, 26, 0.04) 0px 1px 0px, rgba(17, 17, 26, 0.06) 0px 0px 8px ;

    .stats-block-header {
      font-weight: 800;
      margin-bottom: 5px;
    }

    .stats-block-body {
      flex: 1;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    .stats-block-footer{
      display: flex;
      width: 100%;
      justify-content: flex-end;

      .pairwise-agreement-btn{
        border: none;
        background-color: transparent;
        outline: none;

        &:hover{
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }

  .stat-annotators-list{
    display: flex !important;
    gap: 5px;
    padding: 2px 5px;
    overflow-x: auto;

    .annotator-icon{
      border-radius: 100%;
      width: 30px;
      min-width:30px;
      height: 30px;
      display: flex;
      color: white;
      background-color: blue;
      justify-content: center;
      align-items: center;

      &:hover{
        cursor: pointer;
        box-shadow: rgba(17, 17, 26, 0.04) 0px 1px 0px, rgba(17, 17, 26, 0.06) 0px 0px 8px;
      }

      &.excluded{
        background-color: lightblue;
        color: #fff;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }
  }


  table {
    border-collapse: collapse;
    margin: 0;
    margin-bottom: 40px;
  }

  .pairwise-cohens-table{
    width: 95%;
  }

  .avg-cohens-table {
    width: 40%;
    min-width: 500px;

    .outlier{
      background-color: #ffcccc; 
      color: #b30000; 
      font-weight: bold; 
      border: 2px solid #ff6666; 
    }
  }

  table th,
  table td {
      border: 1px solid #ddd;
      padding: 8px;
      text-align: center;
      min-width: 200px;
  }

  table th {
      background-color: #f4f4f4;
      font-weight: bold;
      max-width: 30px;
      overflow: hidden;
      text-overflow: ellipsis;

      &:hover{
        cursor: pointer;
      }
  }

  table tr:nth-child(even) {
      background-color: #f9f9f9;
  }

  table tr:hover {
      background-color: #e1e1e1;
  }

  input {
      margin-bottom: 10px;
      padding: 5px;
      border: 1px solid #ccc;
      border-radius: 4px;
  }

  .highlight-row {
      background-color: #ffebee; /* Light red for highlighting */
  }
}

.instances-stats {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  box-sizing: border-box;

  .instances-stats-header {
    font-size: 18px;
    font-weight: 800;
    margin-bottom: 10px;
  }

  .instances-stats-body {
    display: flex;
    flex-direction: column;
    width: 100%;

    .instances-list {
      display: flex;
      width: 100%;
      min-height: 80px;
      gap: 5px;
      padding: 10px;
      align-items: center;
      
      box-sizing: border-box;

      overflow-x: scroll;
      
      .instance-card {
        height: 100%;
        min-width: 150px;
        max-height: 40px;
        display: flex;
        align-items: center;
  
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        padding:  5px;
        border-radius: 3px;
        box-shadow: rgba(0, 0, 0, 0.06) 0px 1px 3px, rgba(0, 0, 0, 0.12) 0px 1px 1px;
        background-color: white;
        border: 1px solid #C8F9F2;

        box-sizing: border-box;

        &:hover {
          cursor: pointer;
          background-color: #e6fcf9;
        }

        &.selected {
          background-color: #C8F9F2;
          border-color: #C8F9F2;
        }
      }
    }

    .instance-stats-section {
      display: flex;
      flex-direction: column;
      padding: 10px;
      gap: 10px;
      width: 100%;
      position: relative;

      .instance-general-stats{
        display: flex;
        gap: 20px;
        overflow-y: auto;
        padding: 5px 10px;
        box-shadow: rgba(17, 17, 26, 0.04) 0px 1px 0px, rgba(17, 17, 26, 0.06) 0px 0px 8px;

        .stats-block{
          display: flex;
          flex: 1;
          flex-direction: column;
          min-width: 200px;
          min-height: 100px;
          padding: 10px 15px;
          box-shadow: rgba(17, 17, 26, 0.04) 0px 1px 0px, rgba(17, 17, 26, 0.06) 0px 0px 8px ;
          gap: 5px;

          .stats-block-header{
            font-weight: 800;
          }
        }

        .annotators-list{
          display: flex;
          gap: 5px;
          padding: 2px 5px;
          overflow-x: auto;

          .annotator-icon{
            border-radius: 100%;
            width: 30px;
            min-width:30px;
            height: 30px;
            display: flex;
            color: white;
            justify-content: center;
            align-items: center;
            background-color: blue;

            &:hover{
              cursor: pointer;
              box-shadow: rgba(17, 17, 26, 0.04) 0px 1px 0px, rgba(17, 17, 26, 0.06) 0px 0px 8px;
            }

            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }

      .annotation-charts{
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        background-color: #EDFDFC;
        padding: 10px;
        box-shadow: rgba(17, 17, 26, 0.04) 0px 1px 0px, rgba(17, 17, 26, 0.06) 0px 0px 8px inset;
        width: 100%;
        align-items: center;
        min-height: 300px;

        overflow: auto;
        gap: 10px;
        
        .annotation-chart{
          display: flex;
          justify-content: center;
          align-items: center;
          max-width: 50%;
          min-width: 400px;
          min-height: 300px;
          height: auto;
          width: auto;

          box-shadow: rgba(17, 17, 26, 0.04) 0px 1px 0px, rgba(17, 17, 26, 0.06) 0px 0px 8px;
            
          &:hover {
            cursor: pointer;
          }
        }

        .chart-controls {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          gap: 15px;
  
          button{
            height: 30px;
            font-size: 12px;
            color:white;
            background-color: #E90C2D;
            padding: 4px 10px;
            border-radius: 4px;
            margin: 0;
            border: none;
          
            &:hover {
              background-color: #C50720;
              cursor:pointer;
            }
          
            &:disabled {
              background-color: #e0e0e0e0;
              color: #666666;
              cursor: not-allowed;
            }
          
            &:focus {
              outline: none;
            }
          
            &.hidden {
              visibility:hidden;
            }
          }
        } 
      }
    }
  }
}

.statistics-footer {
  display: flex;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;
}


.not-found-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;

  .status-code-text{
    font-size: 90px;
    font-weight: bold;
  }

  .not-found-img{
    max-width: 200px;
  }

  .main-msg-text{
    font-weight: bold;
    font-size: 25px;
  }

  .secondary-msg-text{
    font-size: 14px;
  }
}

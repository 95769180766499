.dataset-selection-container {
  width: 45vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  h5 {
    margin: 0;
  }

  .datasets-section {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    overflow-y: auto;
    border: 1px solid #C8F9F2;
    border-radius: 5px;
    background-color: rgba(237, 253, 251, 0.5);
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px inset;
    padding: 5px;

    .dataset-grid-container{
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;

      .dataset-cards-grid {
        display: grid;
        grid-template-columns:  repeat(auto-fill, minmax(200px, 1fr));
        width: 100%;
        height:100%;
  
        .dataset-card {
          display: flex;
          flex-direction: column;
          box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
          background-color:white;
          padding: 8px;
          aspect-ratio: 1/1;          
  
          &:hover {
            cursor: pointer;
            background-color: #A3F5EA;
          }
  
          &.selected {
            background-color: #A3F5EA;
          }
  
          .card-fields-group {
            display: flex;
            flex-direction: column;
  
            .card-field {
              white-space: nowrap;
              text-overflow: ellipsis;
              max-width: 100%;
              overflow-y: auto;
              overflow-x: hidden;
              margin: 0;
  
              &:hover {
                cursor: pointer;
              }
            }
  
            .card-title {
              font-weight: 500;
            }
  
            .card-date {
              color: rgb(176, 176, 176);
              font-size: 12px;
            }
          }
  
          .card-icon-container {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
  
            .card-icon {
              max-width: 70%;
            }
          }
        }
      }
    }
  }

  .loading-indicator {
    font-size: 20px;
    position: relative;

    .spinner {
      border: 6px solid rgba(0, 0, 0, 0.1);
      border-left: 6px solid #3498db;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      animation: spin 1s linear infinite;
      margin-right: 10px;
    }
    
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }  
  }
}

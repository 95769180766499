
/* Styling of Label Selection page */
.label-selection-container {
  min-width: 40vw;

  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 3vh;

  & h5{
    margin: 0;
  }

  & .label-utils-section {
    width: 100%;
    
    display: flex;
    align-items: center;
    justify-content: space-between;

    & h5 {
      margin: 0;
    }

    & .label-utils {
      margin-left: 60px;

      display: flex;
      align-items: center;
      justify-content: center;

      position: relative;

      & #label-name-input{
        width:15vw;
        border: 1px solid #A3F5EA;
        border-radius: 4px;
        padding: 3px 10px;
      }

      & button {
        font-size: 12px;
        height: 30px;
        display: inline-flex;
        align-items: center;

        margin-left: 10px;
        padding: 4px 10px;

        /* background-color: #464646; */
        
        background-color: white;
        color: black;
        border: 1px solid #A3F5EA;
        border-radius: 4px;

        &:hover{
          cursor: pointer;
          background-color: #DAFBF7;
        }

        &:focus{
          outline: none;
        }

        & img {
          max-width: 20px;
        }
      }

      & .add-button {
        background-color: #b32214;
        color: white;
        border: 1px #A3F5EA solid;

        &:hover {
          background-color: #121010;
          color: white;
        }

        &:disabled {
          background-color: #DAFBF7;;
          color: #666666;
          cursor: not-allowed;
        }
      }

      .color-picker {
        position: absolute;
        top: 0;
        margin-top: 40px;
      }
    }
  }
  
  & .labels-list-section {
    width: 100%;
    min-height: 30vh;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
  
    border-radius: 5px;
    /* background-color: rgba(255, 255, 255, 0.7); */
    background-color: rgba(237, 253, 251, 0.5);
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px inset;
    border: 1px solid #C8F9F2;

    overflow-y: auto;

    & .labels-list {
      display:flex;
      flex-direction: column;
      justify-content: flex-start;

      height: 100%;
      padding: 5px;
      gap: 5px;

      & .labels-list-item{
        min-height: 35px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding: 0px 10px;

        border-radius: 3px;
        height: 5vh;

        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
        background-color:white;

        & .label-info{
          display: flex;
          align-items: center;

          & span {
            max-width: 20vw;
            overflow-x:hidden;
          }

          & .label-color-box {
            width: 15px;
            height: 15px;
            background-color: green;
            margin-left: 10px;
          }
        }

        & .remove-label-button {
          border: none;
          color: #911c12;
          background-color: white;
          font-weight: 500;

          &:hover{
            text-decoration: underline;
            cursor: pointer;
          }

          &:focus {
            outline: none;
          }
        }
      }
    }

    & .no-labels-message {
      font-size: 18px;
      text-align: center;
      color: #ccc;
    }
  }
}
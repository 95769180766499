.project-status-modal {
    width: 30vw;
    min-width: 400px;
    max-width: 600px;
    height: 50vh;
    padding: 20px;
}

.project-status-modal-body {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.update-options-container {
    width: 100%;
}

.update-message {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 30px;
    color: #555;
}

.buttons-container {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.btn {
    font-size: 14px;
    padding: 8px 15px;
    border-radius: 6px;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out;
}

.cancel-btn {
    background-color: #777;
    color: #fff;
}

.cancel-btn:hover {
    background-color: #555;
    transform: scale(1.05);
}

.update-btn {
    background-color: #E90C2D;
    color: white;
}

.update-btn:hover {
    background-color: #C50720;
    transform: scale(1.05);
}

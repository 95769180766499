.assignments-modal {
  width: 70vw;
  min-width: 500px;

  .assignments-modal-body {   
    display: flex;
    flex-direction: column;
    height: 100%;

    .data-chunks-container{
      display: flex;
      flex-direction: column;

      .data-chunks-list{
        overflow-y: auto;
        border: 1px solid #C8F9F2;
        border-radius: 5px;
        /* background-color: rgba(237, 253, 251, 0.5); */
        box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px inset;
        padding: 5px;
        gap: 4px;
        display: flex;

        .data-chunk-item{
          display: flex;
          align-items: center;
          justify-content: center;

          min-width: 75px;
          height: 25px;

          box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
          background-color:white;
          border-radius: 5px;

          &:hover{
            cursor: pointer;
            background-color: rgba(237, 253, 251, 0.5);
          }

          &.selected {
            background-color: #021210;
            color: white;
          }
        }


      }
    }

    .assignments-container{
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow-y: hidden;

      .assignments-container-header{
        display: flex;
        justify-content: flex-start;
        padding: 10px 0px;

        .user-filters-section{
          display: flex;
          gap: 10px;

          .user-filter{
            font-size: 20px;
            font-weight: 500;
            background: none;
            border: none;
            color: #A3F5ED;

            &:hover{
              cursor: pointer;
              color: #7EF1E5;
            }

            &:focus{
              outline: none;
            }

            &.selected{
              color: black;

              &:hover{
                color: black;
              }
            }
          }

          .vertical-seperator{
            background-color: black;
            width:1px;
          }
        }
      }

      .assignments-container-body{
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        flex: 1;
        overflow-y: auto;

        .no-users-container{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;

          border: 1px solid #C8F9F2;
          border-radius: 5px;
          /* background-color: #EDFDFC; */
          box-shadow: rgba(17, 17, 26, 0.04) 0px 1px 0px, rgba(17, 17, 26, 0.06) 0px 0px 8px inset;

          padding: 5px;
          height: 100%;
          max-height: 100%;

          .na-icon{
            max-width: 80px;
          }

          .na-text{
            font-weight: 500;
            font-size: 16px;
          }
        }

        .users-list{
          overflow-y: auto;
          border: 1px solid #C8F9F2;
          border-radius: 5px;
          /* background-color: #EDFDFC; */
          box-shadow: rgba(17, 17, 26, 0.04) 0px 1px 0px, rgba(17, 17, 26, 0.06) 0px 0px 8px inset;
          padding: 5px;
          height: 100%;
          width: 100%;
          gap: 1px;
          display: flex;
          flex-direction: column;

          .user-card{
            box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
            background-color:white;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 40px;
            padding: 5px 20px;

            .card-field {
              white-space: nowrap;
              text-overflow: ellipsis;
              max-width: 100%;
              overflow-y: auto;
              overflow-x: hidden;
              margin: 0;
  
              &:hover {
                cursor:default;
              }
            }
  
            .card-title {
              font-weight: 500;
            }
            
            .assignment-btn{
              height: 25px;
              font-size: 12px;
              color:black;
              outline: 1px solid #C8F9F2;
              background-color: white;
              padding: 4px 10px;
              border-radius: 4px;
              margin: 0;
              border: none;

              &:hover {
                cursor:pointer;
                box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
              }
            
              &:disabled {
                background-color: #e0e0e0e0;
                color: #666666;
                cursor: not-allowed;
              }
            
              &:focus {
                outline: none;
              }

              &.pending {
                background-color: #021210;
                color: white;
              }
            }
          }
        }
      }
    }
  }

  .assignments-modal-footer {  
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.instance-dashboard{
  /* Container of annotation setup */
  .label-studio-container{
    display: flex;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    justify-content: center;

    #label-studio{
      display: block;
      width: 100%;
      height: 100%;
      overflow-y: auto;
      justify-content: center;
    }
  }
}


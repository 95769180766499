.annotated-instances-container {
  display: flex;
  justify-content: center;
  align-items: center;

  .project-instances-list {
    display: flex;
    flex-direction: column; 
    gap: 2px;
    width: 100%;
    height: 100%;
    z-index: 10;
  
    .data-chunk-sublist {
      z-index: auto;
      display: flex;
      flex-direction: column;
      gap: 1px;
      align-items: center;
  
      .data-chunk-card {
        z-index: 15;

        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
    
        min-height: 35px;
        height: 40px;
    
        overflow:hidden;
    
        padding: 0px 10px;
        margin-top: 2px;
    
        border-radius: 3px;
        box-shadow: rgba(0, 0, 0, 0.06) 0px 1px 3px, rgba(0, 0, 0, 0.12) 0px 1px 1px;
        background-color: #C8F9F2;
        border: 1px solid #C8F9F2;
    
        .data-chunk-card-title {
          font-weight: bold;
          color: #021627;
        }
    
        .data-chunk-card-utils {
          display: flex;
          height: 30px;

          .annotate-btn {
            font-size: 12px;
            background-color: #E90C2D;
            color: #fff;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            height: 100%;
            margin-right: 4px;

            padding: 2px 8px;
            border-radius: 6px;
            border: none;
            cursor: pointer;
            transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out;
  
            &:hover {
              background-color: #b32214;
              transform: scale(1.025);
            }

            &:disabled{
              background-color: #b3b3b3e0;
              color: #666666;
              cursor: not-allowed;
            }
          
            /* .btn-link {
              display: flex;
              justify-content: center;
              padding: 4px 10px;
              color: #fff !important;
    
              &:hover {
                text-decoration: none;
                color: #fff !important;
              }
            } */
          }
    
          .annotators-btn, .stats-btn {
            background-color: white;
            aspect-ratio: 1/1;
            margin-right: 2px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #A3F5EA;
            height: 100%;
            height: 100%;

            padding: 1px 2px;
            border-radius: 6px;
            cursor: pointer;
  
            img {
              max-width: 20px;
            }
  
            &:hover{
              box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            }
          }
    
          .expand-btn{
            display: flex;
            align-items: center;
      
            &:hover{
              cursor: pointer;
            }
      
            img{
              max-width: 20px;
            }
          }
        }
      }
  
      .instances-cards-header{
        display: flex;
        padding: 0px 10px;
        width: 100%;
        font-size: 12px;
        font-weight: 500;
  
        & .fields-section{
          flex: 10;
          display: flex;
  
          & .header-field{
            flex: 1;
          }
        }
  
        & .empty-section{
          display: flex !important;
          flex: 2;
        }
      }
  
      .instance-card {
        display: flex;
        align-items: center;
  
        width: 99%;
    
        min-height: 30px;
        height: 40px;
    
        overflow:hidden;
    
        padding: 0px 10px;
    
        border-radius: 3px;
        box-shadow: rgba(0, 0, 0, 0.06) 0px 1px 3px, rgba(0, 0, 0, 0.12) 0px 1px 1px;
        background-color:white;
        border: 1px solid #C8F9F2;

        z-index: 15;
    
        .item-fields-section{
          flex: 10;
          display: flex !important;
          width: 100%;
          max-height: 100%;
          overflow: hidden !important;
          text-overflow: ellipsis !important;
          align-items: center !important;
          white-space: nowrap !important;
          
          .item-field{
            padding-right: 10px;
            flex:1;
            text-overflow: ellipsis;
            overflow: hidden;
            font-size: 14px;
            max-height: 100%;
          }
  
          .instance-card-title {
            font-weight: bold;
          }
        }
    
        .item-utils-section{
          flex: 2;
          display: flex;
          justify-content: flex-end;
          height: 30px;
    
          .stats-btn {
            background-color: white;
            border-radius: 4px;
            aspect-ratio: 1/1;
            display: flex;
            justify-content: center;
            align-items: center;
  
            height: 100%;
            
            padding: 1px 2px;
            border-radius: 6px;
            border: none;
            cursor: pointer;
            
            img {
              max-width: 20px;
            }
  
            &:hover{
              cursor: pointer;
              box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
              transform: scale(1.025);
            }
          }
  
          .view-btn {
            font-size: 12px;
            background-color: #E90C2D;
            color: #fff;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            height: 100%;
            margin-right: 4px;

            padding: 1px 2px;
            border-radius: 6px;
            border: none;
            cursor: pointer;
            transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out;
  
            &:hover {
              background-color: #b32214;
              transform: scale(1.025);
            }
          
            .btn-link {
              display: flex;
              justify-content: center;
              padding: 4px 10px;
              color: #fff !important;
    
              &:hover {
                text-decoration: none;
                color: #fff !important;
              }
            }
          }
        }
      }
    }
  }

  .not-available-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 5px;
    height: 100%;
    max-height: 100%;
    width: 100%;

    img{
      max-width: 100px !important;
      margin-bottom: 20px;
    }

    .na-text{
      font-weight: 500;
      font-size: 22px !important;
    }
  }
}
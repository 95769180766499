.auth-page {
  background-color: #91F3EC;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .success-signup-container{
    width: 400px;
    padding: 40px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;  

    .logo-container {
      margin-bottom: 25px;
      width: 100%;
      display:flex;
      justify-content: space-evenly;
    
      .logo {
        width: 100px !important;
      }
    }

    .submit-btn {
      margin-top: 60px;
      background-color: #E90C2D;
      color: #fff;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-size: 16px;
      text-align: center;
      padding: 6px 50px;
      margin-top: 20px;
    
      &:hover {
        background-color: rgb(136, 27, 17);
        box-shadow: 0 2px 6px 0px rgba(0, 0, 0, 0.2);
        text-decoration: none;
        transition: all 150ms linear;
      }

      .navigation-link {
        color: rgb(255, 255, 255);
        text-decoration: none;
    
        &:hover {
          color: rgb(255, 255, 255);
          text-decoration: none;
        }
      }
    }
  }

  .form-container {
    width: 470px;
    max-height: 90vh;
    overflow-y: auto;
    padding: 40px;
  
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;  
  
    z-index: 0;

    .logo-container {
      margin-bottom: 25px;
      width: 100%;
      display:flex;
      justify-content: space-evenly;
    
      .logo {
        width: 75px !important;
      }
    }

    .form-header {
      font-size: 36px;
      font-weight: bold;
      margin-bottom: 60px;
    }

    .form-group {
      margin-bottom: 20px;
      text-align: left;
      position: relative;

      .form-label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1px;
        font-weight: bold;
        position: relative;

        .form-label-title-container{
          display: flex;
          align-items: center;

          .form-label-title{
            font-size: 16px;
          }

          .validity-icon {
            visibility: hidden;
            max-width: 15px;
            margin-left: 10px;
          }

          .validity-icon.visible {
            visibility: visible;
          }
        }

        .info-icon{
          max-width: 18px;
          position: absolute;
          top: 50%;
          right: 0%;
          transform: translate(-50%, -50%);
        }

        .info-popup {
          position: fixed;
          top: 0;
          left: 0;
          opacity: 0;
          transition: opacity 0.5s ease-in-out;
          pointer-events: none;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          padding: 10px;
          color: #f4f4f4;
          margin-left: 10px;
          background-color: #121010;
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
          border-radius: 4px;        
        
          &.visible {  
            opacity: 1;
            pointer-events: auto; /* Enables interactions when visible */
          }
        
          .info-popup-text {
            font-size: 12px;
            width: 180px;
            display: flex;
            flex-direction: column;
            align-items: center;
          
            ul{
              font-size: 10px;
              padding-left: 15px;
              padding-right: 15px;
              padding-top: 10px
            }
          }
        }
      }

      .form-input {
        height:35px;
        font-size: 14px;
        width: 100%;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-sizing: border-box;
      
        position: relative;
      }
      
      .form-input.invalid {
        border: 1px solid #b32214;
      }

      .visibility-input-container {
        display: flex;
        align-items: center;
        justify-content:flex-end;
      
        .visibility-icon{
          max-width: 18px;
          position: absolute;
          top: 50%;
          right: 0%;
          transform: translate(-50%, -50%);
        }
      
        .password-toggle {
          position: absolute;
          cursor: pointer;
          display: inline;
          margin-right: 5px;
        } 
      }
    }

    .submit-btn {
      margin-top: 60px;
      background-color: #E90C2D;
      color: #fff;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-size: 16px;
      text-align: center;
      padding: 6px 50px;
    
      &:hover {
        background-color: rgb(136, 27, 17);
        box-shadow: 0 2px 6px 0px rgba(0, 0, 0, 0.2);
        text-decoration: none;
        transition: all 150ms linear;
      }

      .navigation-text {
        margin-top: 20px;
        font-size: 14px;
    
        .navigation-link {
          color: rgb(255, 255, 255);
          text-decoration: none;
      
          &:hover {
            color: rgb(255, 255, 255);
            text-decoration: none;
          }
        }
      }
    }

    .navigation-text {
      margin-top: 20px;
      font-size: 14px;
    
      .navigation-link {
        color:rgb(136, 27, 17) !important;
        text-decoration: none;
      
        &:hover {
          text-decoration: underline;
        }
      }
    }

    .error-popup {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      margin-top:20px;
      font-size: 12px;
      background-color: #121010;
      color: #f4f4f4;
      text-align: center;
      padding: 10px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);  
      border-radius: 4px;
      opacity: 0; 
      transition: opacity 0.5s ease-in-out;
    }
    
    .error-popup.visible {
      opacity: 1; 
    }
  }
}
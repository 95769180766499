.instance-selection-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 50vw;
  height: 100%;

  overflow-y: auto;

  & .instances-section {
    width: 100%;
    height: 100%;
    min-height: 200px;

    display: flex;
    flex-direction: column;

    margin-top: 20px;   
    overflow: hidden;

    & .instances-section-header {
      position: relative;
      display: flex;
      justify-content: space-between;
    }

    & .instances-section-body {
      position: relative;

      height: 100%;
      width: 100%;
      overflow: hidden;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  
      border: 1px solid #C8F9F2;
      border-radius: 5px;
      background-color: rgba(237, 253, 251, 0.5);
      box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px inset; 

      padding: 5px;
      gap: 4px;

      & .instances-fields-header{ 
        width: 100%;
        display: flex !important;
        padding: 0px 10px;

        & .fields-section{
          flex: 10;
          display: flex !important;

          & .header-field{
            flex: 1;
          }
        }

        & .empty-section{
          display: flex;
          flex: 2;
        }
        
      }

      .instances-list {
        height: 100%;
        width: 100%;
        overflow-y: auto;

        display: flex;
        flex-direction: column;

        & .folder-sublist {
          display: flex;
          flex-direction: column;
          gap: 1px;
          width: 100%;
  
          & .folder-sublist-folder-item {
            display: flex !important;
            position: relative;
            align-items: center;
            justify-content: space-between;
  
            min-height: 35px;
            height: 35px;
  
            overflow:hidden;
      
            padding: 0px 10px;
            margin-top: 2px;
      
            border-radius: 3px;
    
            box-shadow: rgba(0, 0, 0, 0.06) 0px 1px 3px, rgba(0, 0, 0, 0.12) 0px 1px 1px;
            background-color: #C8F9F2;
            border: 1px solid #C8F9F2;

            width: 100%;

            & .item-fields-section{
              flex: 1;
              display: flex !important;
              width: 100%;
              height: 100%;
              align-items: center;
              
              & .item-field{
                flex:1;
                text-overflow: ellipsis;
                overflow: hidden;
              }
            }

            & .item-utils-section{
              flex: 0 !important;
              display: flex !important;
              align-items: center;
              justify-content: space-between;
              gap: 10px;

              & .expand-btn{
                display: flex;
                align-items: center;

                & :hover{
                  cursor: pointer;
                }

                & img{
                  max-width: 20px;
                }
              }
            }
          }
  
          & .folder-sublist-file-item {
            display: flex !important;
            align-items: center;

            min-height: 30px;
            height: 30px;
  
            overflow:hidden;
      
            padding: 0px 10px;
      
            border-radius: 3px;
  
            box-shadow: rgba(0, 0, 0, 0.06) 0px 1px 3px, rgba(0, 0, 0, 0.12) 0px 1px 1px;
            background-color:white;
            border: 1px solid #C8F9F2;

            width: 100%;

            & .item-fields-section{
              flex: 10;
              display: flex !important;
              width: 100%;
              max-height: 80%;
              text-overflow: ellipsis;
              overflow: hidden;
              
              & .item-field{
                padding-right: 10px;
                flex:1;
                text-overflow: ellipsis;
                overflow: hidden;
                width: 100%;
                height: 100%;
                word-break: normal; /* Ensures text doesn't break arbitrarily */
                overflow-wrap: normal; /* Prevents breaking long words */
                white-space: nowrap; /* Prevents breaking within the text */
              }
            }

            & .item-utils-section{
              flex: 2;
              display: flex;
              justify-content: flex-end;
            }
          }
        }
      }
    }
  }
}

.checkbox-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  cursor: pointer;
  user-select: none;

  input {
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    margin-right: 10px;
  }
  
  .checkbox-label {
    margin: 0;
  }    
  
  .check-mark {
    height: 15px;
    width: 15px;
    display: flex;
    align-items: center;
    justify-content: center; /* Center the checkmark horizontally and vertically */
    border-radius: 4px;
    border: 1px solid #ccc;
    background-color: white;
  }

  /* On mouse-over, add a grey background color */
  .checkbox-item:hover input ~ .check-mark {
    border: 1px solid #021210;
  }

  /* When the checkbox is checked, add a blue background */
  input:checked ~ .check-mark {
    background-color: #021210;
  }

  /* Show the checkmark when checked */
  input:checked ~ .check-mark:after {
    display: block;
    content: "";
    width: 5px; /* Adjusted width for better proportion */
    height: 9px; /* Adjusted height for better proportion */
    border: solid white;
    border-width: 0 2px 2px 0;
    position: absolute;
    transform: rotate(45deg);
    top: 1px;
  }

  /* Style the disabled checkbox */
  input:disabled ~ .check-mark {
    cursor: not-allowed;
    background-color: #FEEBEE;
  }

  .checkbox-item:hover input:disabled ~ .check-mark {
    border: 1px solid #ccc;
  }
}


.data-split-container {
  display: flex;
  flex-direction: column;
  width: 100%;

  h6{
    margin: 0;
  }

  .splits-selection-section{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    
    .range-slider{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 1rem;
      padding: 0px 30px;
      width: 40%;

      input[type="range"] {
        -webkit-appearance: none;
        appearance: none; 
        width: 100%;
        min-width: 100px;
        cursor: pointer;
        outline: none;
        border-radius: 15px;  
        height: 6px;
        background: #DAFBF7;

        &:disabled {
          cursor: not-allowed;
        }

        &::-webkit-slider-thumb {
          /* removing default appearance */
          -webkit-appearance: none;
          appearance: none; 
          /* creating a custom design */
          height: 15px;
          width: 15px;
          background-color: #FFA21F;
          border-radius: 50%;
          border: none;
          transition: .2s ease-in-out;

          &:hover {
            box-shadow: 0 0 0 10px rgba(255,85,0, .1)
          }
        }
        
        /* Thumb: Firefox */
        &::-moz-range-thumb {
          height: 15px;
          width: 15px;
          background-color: #FFA21F;
          border-radius: 50%;
          border: none;  
          transition: .2s ease-in-out;

          /* Hover, active & focus Thumb: Firfox */
          &:hover {
            box-shadow: 0 0 0 10px rgba(255,85,0, .1)
          }
        } 
      }
      
      .slider-value {
        font-size: 16px;    
        text-align: center;
      }
    }

    input[type="number"]{
      &:disabled {
        cursor: not-allowed;
      }
    }
  }
}

.loading-indicator {
  font-size: 20px;
  position: relative;

  .spinner {
    border: 6px solid rgba(0, 0, 0, 0.1);
    border-left: 6px solid #3498db;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
    margin-right: 10px;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }  
}
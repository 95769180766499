.filters-modal {
  width: 50vw;
  min-width: 500px;

  .filters-modal-body{
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .clear-filters-button {
      border: none;
      background-color: transparent;
      cursor: pointer;
      font-size: 12px;
      font-weight:500;
      margin-top: 5px;
    
      &:hover{
        color: #464646;
        text-decoration: underline;
      }
    
      &:focus {
        outline: none;
      }
    }
  
    .filters-grid-container {
      width: 100%;
      display:grid;
      grid-template-rows: auto auto; 
      grid-template-columns: 1fr 1fr;
      gap: 80px;
      overflow-y: auto;
      padding: 10px 40px;
  
      .single-filter-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;
  
        h6 {
          margin: 0;
          line-height: normal;
        }
      
        .clear-filters-button {
          margin: 0;
          margin-top: 0;
        }
      }
  
      .first-row {
        grid-column: span 2;
      }
  
      .date-filters-container {
        display: flex;
        align-items: center;
  
        .date-filter {
          flex: 1;
  
          .react-date-picker__wrapper {
            width: 15vw;
            min-width: 150px;
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
          
            .react-date-picker__inputGroup {
              font-size: 12px;
            }
          
            svg{
              max-width: 12px;
            }
          
            button:focus, & .react-date-picker__clear-button:focus, & .react-date-picker__button:focus, & input:focus{
              outline:none;
            }
          }
        }
      }
  
      .checkbox-list-container {
        width: 100%;
  
        .checkbox-list-entry {
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: relative;
          cursor: pointer;
          user-select: none;
  
          input {
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
          }
  
          .checkbox-label {
            margin: 0;
          }        
  
          .check-mark {
            height: 15px;
            width: 15px;
            display: flex;
            align-items: center;
            justify-content: center; /* Center the checkmark horizontally and vertically */
            position: relative;
            border-radius: 4px;
            border: 1px solid #ccc;
          }
  
          /* On mouse-over, add a grey background color */
          &:hover input ~ .check-mark {
            border: 1px solid #464646;
          }
  
          /* When the checkbox is checked, add a blue background */
          input:checked ~ .check-mark {
            background-color: #464646;
          }
  
          /* Show the checkmark when checked */
          input:checked ~ .check-mark:after {
            display:block;
          }
  
          /* Style the checkmark/indicator */
          .check-mark:after {
            content: "";
            width: 5px; /* Adjusted width for better proportion */
            height: 9px; /* Adjusted height for better proportion */
            border: solid white;
            border-width: 0 2px 2px 0;
            position: absolute;
            transform: rotate(45deg);
            top: 1px;
          }
  
          /* Create the checkmark/indicator (hidden when not checked) */
          .check-mark:after {
            content: "";
            display: none;
          }
        }
      }
    }
  }

  .filters-modal-footer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .clear-filters-button {
      border: none;
      background-color: transparent;
      color: #911c12;
      cursor: pointer;
      font-size: 12px;
      font-weight:500;
      margin-top: 5px;
    
      &:hover{
        color: #464646;
        text-decoration: underline;
      }
    
      &:focus {
        outline: none;
      }
    }
  }

}
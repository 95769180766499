.utils-container{
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 35px;
  width: 100%;

  .utils-section{
    display: flex;
    align-items: center;
    gap: 5px;
    height: 100%;

    .util-btn{
      height:100%;
      padding: 5px 10px;
      font-size: 12px;

      display: flex;
      align-items: center;
      justify-content: space-around;

      color:black;
      background-color: #FDFFFC;

      border: 1px solid #A3F5EA;
      border-radius: 4px;

    
      &:hover {
        background-color: #DAFBF6;
        cursor:pointer;
      }
    
      &:focus {
        background-color: #DAFBF6;
        outline:none;
      }

      .filter-btn-icon {
        margin:0;
        border-radius: 0px;
        max-width: 20px;
      }

      .sort-btn-text{
        margin: 0;
        @media screen and (max-width: 768px) {
          display: none;
        }
      }
      
      .main-sort-icon{
        max-width: 7px;
        margin-left: 7px;

        @media screen and (max-width: 768px) {
          display: none;
        }
      }
      
      .second-sort-icon{
        display: none;

        @media screen and (max-width: 768px) {
          max-width: 20px;
          display: block;
        }
      }

      .dropdown-menu{
        padding:0;
      
        .dropdown-item:hover {
          background-color: aliceblue;
        }
      
        #SortByDropdown:focus,
        #SortByDropdown:focus-within:focus {
          background-color: #121010; /* Set the background color when in focus or open */
        }
      }
    }

    .util-searchbar {
      width: 25vw;
      border-radius: 3px;
      border: 1px solid #A3F5EA;
      font-size: 10px;
      padding: 5px 10px;
      height:100%;
    }
  }

  .create-project-btn, .upload-dataset-btn, .annotate-btn {
    height:100%;

    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;

    color:white;
    background-color: #E90C2D;

    font-size: 14px;
    padding: 8px 15px;
    border-radius: 6px;
    border: none;
    cursor: pointer;

    transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out;

    &:hover {
      background-color: #C50720;
      transform: scale(1.025);
    }
  
    &:focus{
      background-color: #C50720;
      outline:none;
    }

    &:disabled{
      background-color: #e0e0e0e0;
      color: #666666;
      cursor: not-allowed;
    }

    .create-btn-icon, .upload-btn-icon{
      display: none;

      @media screen and (max-width: 768px) {
        display: block;
        max-width: 20px;
      }
    }

    .create-btn-text, .upload-btn-text{
      margin: 0;
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  } 
}
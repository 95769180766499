.creation-modal, .filters-modal, .dataset-upload-modal, .assignments-modal, .modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  height: 80vh;

  display: flex;
  flex-direction: column;

  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  border-radius: 10px;

  z-index: 35;

  & .creation-header, .datasets-modal-header, .filters-modal-header, .assignments-modal-header, .modal-header {
    width: 100%;
    min-height: 70px;
    padding: 30px;

    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;

    border: none;

    border-bottom: #A3F5EA 1px solid;

    & h3 {
      font-size: 24px;
      margin: 0;
      color: #333;
    }
  
    & .x-icon {
      margin: 0;
      border-radius: 0px;
      max-width: 20px;
      position: absolute;
      right: 40px;
      transition: transform 0.2s ease-in-out;
      cursor: pointer;

      &:hover {
        transform: scale(1.1);
        
      }
    }
  }

  & .creation-body, .datasets-modal-body, .filters-modal-body, .assignments-modal-body, .modal-body {
    flex: 1;
    width: 100%;
    height: 100%;

    display: flex;

    overflow: auto;

    padding: 2vh 4vw;
  }

  & .creation-footer, .datasets-modal-footer, .filters-modal-footer, .assignments-modal-footer, .modal-footer {
    width: 100%;
    min-height: 70px;
    display: flex;
    padding: 30px;
    
    /* padding: 0px 20px; */

    border-top: #A3F5EA 1px solid;

    & .submission-footer-btn {
      height: 30px;
      font-size: 12px;
      color:white;
      background-color: #E90C2D;
      padding: 4px 10px;
      margin: 0;
      border: none;

      border-radius: 6px;
      cursor: pointer;
      transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out;
    
      &:hover {
        background-color: #C50720;
      }

      &:disabled {
        background-color: #e0e0e0e0;
        color: #666666;
        cursor: not-allowed;
      }
    
      &:focus {
        outline: none;
      }
    
      &.hidden {
        visibility:hidden;
      }
    }

    .util-footer-btn {
      height: 30px;
      font-size: 12px;
      color:white;
      background-color: #464646;
      padding: 4px 10px;
      border-radius: 4px;
      margin: 0;
      border: none;
    
      &:focus{
        outline: none;
      }
    
      &:hover {
        background-color: #121010;
        cursor: pointer;
      }
    }
  }

  .message-container{
    height: 100%;
    width: 100%;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border-radius: 12px;
    border: 1px solid #e0e0e0;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.08) 0px 0px 8px inset;

    .message-icon{
      max-width: 100px;
      margin-bottom: 10px;
    }

    .empty-box-icon{
      animation: gentle-shake 0.5s ease-in-out;
      animation-iteration-count: 2;
      animation-delay: 0s; /* Shake every 5 seconds */
      animation-fill-mode: forwards;

      &:hover {
        animation-delay: 0s; /* Shake immediately on hover */
      }
    }

    .message-text, .message-text-main {
      color: #721c24;
      font-size: 16px;
      text-align: center;
      margin: 5px 0;
      text-decoration: none;
    }
    
    .message-text-main {
      font-weight: bold;
      font-size: 20px;
      text-decoration: none;
      margin: 0;
    }
  }

  .uploading-indicator {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 12px;
    border: 1px solid #e0e0e0;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.08) 0px 0px 8px inset;

    width: 100%;
    height: 100%;

    img {
        width: 120px;
        height: 120px;
        margin-bottom: 15px;
    }
    
    div {
        font-size: 16px;
        font-weight: 500;
        color: #333; 
    }
  }

  .loading-indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.08) 0px 0px 8px inset;
    width: 100%;
    height: 100%;

    .spinner {
        border: 4px solid rgba(0, 0, 0, 0.1);
        border-left: 4px solid #3498db;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        animation: spin 1s linear infinite;
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
  }
}

::-webkit-scrollbar {
  width: 4px;              
  height: 4px;             
}

::-webkit-scrollbar-track {
  background: #f1f1f1;      
  border-radius: 12px;      
}

::-webkit-scrollbar-thumb {
  background: #888;        
  border-radius: 12px;     
}

::-webkit-scrollbar-thumb:hover {
  background: #555;        
}


@keyframes gentle-shake {
  0% { transform: translate(0, 0) rotate(0deg); }
  25% { transform: translate(-1px, 1px) rotate(-0.5deg); }
  50% { transform: translate(1px, -1px) rotate(0.5deg); }
  75% { transform: translate(-1px, 1px) rotate(-0.5deg); }
  100% { transform: translate(0, 0) rotate(0deg); }
}
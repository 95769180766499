p{
  margin: 0;
}

.dashboard-page{
  font-family: Montserrat, sans-serif;
  width: 100%;
  height: 100%;

  padding-top: 80px;
  display: flex;
  
  .dashboard-section {
    flex: 1;
  
    display: flex;
    overflow-y: auto;
    padding: 5px;
    width: 100%;
  }
}

.video {
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: cover;
  z-index: -100;
  opacity: 0%;
}
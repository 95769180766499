.dashboard-side-bar {      
  width: 60px;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 5px;

  border: 1px solid #A3F5EA;
  /* background-color: #F0F4F8; */

  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;

  @media screen and (max-width: 768px) {
    position: absolute;
  }

  & ul {
    list-style-type: none;
    width: 100%;
    padding: 0;
    margin: 0;

    .side-bar-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      
      width: 100%;
      aspect-ratio: 1/1;
      padding: 8px;
      margin-bottom: 4px;
      
      border-radius: 8px;
      border: 1px solid transparent;
      
      /* background-color: #F0F4F8; */
      transition: all 0.3s ease;
  
      color: #333;
      text-decoration: none;
  
      &:hover {
        background-color: #E0F7FA;
        cursor: pointer;
      }
  
      &.selected {
        background-color: #FFFFFF;
        border: 1px solid #A3F5EA;
        font-weight: 600;
        color: #005662;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      }
  
      & img {
        max-width: 27px;
      }
  
      & .bar-item-name {
        font-size: 12px;
        margin: 0;
        font-weight: 400;
      }
    }
  }
}

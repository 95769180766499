.dashboard, .annotated-instances-dashboard{
  width:100%;
  height: 100%;

  padding: 5px 5px;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  z-index: 0;

  @media (max-width: 768px) {
    padding: 80px 0px;
  }

  h2{
    padding: 10px;
  }

  .project-cards-container, .dataset-cards-container, .project-instances-container, .label-studio-container, .annotated-instances-container, .annotators-container{
    width: 100%;
    height: 100%;
    overflow-y: auto;

    margin-top: 5px;
    padding: 10px 10px;

    border-radius: 5px;
    /* background-color: #EDFDFC; */
    box-shadow: rgba(17, 17, 26, 0.04) 0px 1px 0px, rgba(17, 17, 26, 0.06) 0px 0px 8px inset;
    border: 1px solid #A3F5EA;
    
    z-index: 5;

    display: flex;
    justify-content: center;
    align-items: center;

    a{
      color: #fff;

      &:hover{
        color: #fff;
      }
    }

    .dataset-card, .project-card, .annotator-card{
      border-radius: 12px;
      /* background-color: #f9f9f9; */
      background-color: #ffffff; 
      border: 1px solid #e0e0e0;
      box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
      transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

      &:hover {
        /* background-color: rgba(0, 0, 0, 0.01); */
        transform: translateY(-1px);
        box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 8px;
        cursor: pointer;
      }
    }

    .loading-indicator {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
  
      .spinner {
        border: 6px solid rgba(0, 0, 0, 0.1);
        border-left: 6px solid #3498db;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        animation: spin 1s linear infinite;
        margin-right: 10px;
      }
      
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }  
    }

    .not-available-container{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      padding: 5px;
      height: 100%;
      max-height: 100%;

      .na-icon{
        max-width: 100px;
      }

      .na-text{
        font-weight: 500;
        font-size: 18px;
      }
    }
  }

  .no-projects-container, .no-datasets-container {
    width:100%;
    height: 100%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    font-size: 20px;
    color:#464646;
  }


  .message-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 40px;

    border-radius: 12px;
    border: 1px solid #e0e0e0;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;

    .message-icon{
      max-width: 100px;
      margin-bottom: 10px;
    }

    .message-text, .message-text-main {
      color: #721c24;
      font-size: 16px;
      text-align: center;
      margin: 5px 0;
      text-decoration: none;
    }
    
    .message-text-main {
      font-weight: bold;
      font-size: 20px;
      text-decoration: none;
      margin: 0;
    }
  }
}


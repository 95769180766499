.full-screen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
  z-index: 9999; /* ensure it's in front of everything */
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 10000; /* Keep content above the overlay */
}

.utils-container{

  .annotation-progress-container{
    display: flex;
    align-items: center;
    font-family: Arial, sans-serif;
    font-size: 14px;
    border: 1px solid #A3F5EA;
    border-radius: 4px;
    padding: 5px;
    flex-direction: column;
    
    .progress-text-container{
      flex: 1;

      .label {
        margin-right: 10px;
      } 
    }

    .progress-bar-container{
      flex: 1;
      width: 100%;

      .progress-bar{
        height: 10px;
        border-radius: 3px;
        width: 100%;
        position: relative;
        background-color: white;
        border: 1px solid #C8F9F2;
      }

      .completion-bar{
        width: 15%;
        position: absolute;
        height: 100%;
        background-color: #FFA21F;
        border-radius: 3px;
      }
    }

    
  }

  .main-utils-section{
    display: flex;
    gap: 5px;
    height: 100%;
    justify-content: flex-end;

    .status-btn {
      background-color: white;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      padding: 2px 5px;
      border: 1px solid #A3F5EA;
      min-width: 120px;

      &:hover{
        cursor: pointer;
      }

      &:focus{
        outline: none;
      }
      
      .status-text {
        margin: 0;
        font-size: 14px;
      }

      .status-circle {
        width: 10px; 
        height: 10px; 
        border-radius: 50%; 
        background-color: #23E7D3; 
        margin-left: 15px;
      }

    }

    .main-utils-btn {
      background-color: white;
      border-radius: 4px;
      aspect-ratio: 1/1;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #A3F5EA;
      height: 100%;
      padding: 2px;

      img {
        max-width: 20px;
      }

      &:hover{
        cursor: pointer;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      }
    }


    .project-status-switch-container {
      display: flex;
      align-items: center;
      font-family: Arial, sans-serif;
      font-size: 14px;
      border: 1px solid #A3F5EA;
      border-radius: 4px;
      padding: 5px;
      
      .project-status-circle {
        border-radius: 50%; 
        width: 12px;
        height: 12px;
        display: inline-block;
        transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out; 
        background-color: lightblue;
    
        &:hover {
            cursor: pointer;
            transform: scale(1.05); 
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05); 
        }
      }

      .label {
          margin-right: 10px;
      }
  
      .status-switch {
          width: 40px;
          height: 20px;
          border-radius: 50px;
          background-color: purple; /* Inactive background color */
          position: relative;
          cursor: pointer;
          transition: background-color 0.3s;
      }
  
      .status-switch.active {
          background-color: lightgreen; /* Active background color */
      }
      
      .slider {
          width: 15px;
          height: 15px;
          background-color: #fff;
          border-radius: 50%;
          position: absolute;
          top: 50%;
          left: 2px;
          transform: translateY(-50%);
          transition: transform 0.3s, left 0.3s;
      }
      
      .status-switch.active .slider {
          left: 22px; /* Move the slider to the right end */
      }
    }
  
  }
}
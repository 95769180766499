.about-container{
    width: 100%;
    height: 100%;
    padding-top: 80px;
    display: flex;

    .side-options {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
        min-width: 300px;

        .logo-header{
            font-size: 20px;
            font-weight: 500;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 8px 10px;

            img{
                max-width: 45px;
                margin-right: 5px;
            }
        }

        .options{
            display: flex;
            height: 100%;
            flex-direction: column;
            padding: 20px 0px;
            background-color: #B6F7F1; 
            gap: 12px;
            overflow-y: auto;

            .option-header{
                color: #E90C2D; 
                font-weight: 600;
                margin-bottom: 0px;
                padding: 0px 25px;
            }

            .option{
                padding: 2px 25px;
                cursor: default;

                &:hover{
                    background-color: #8FEAE1;
                    cursor: pointer;
                }
            }
        }

        .git-footer{
            display: flex;
            width: 100%;
            color: #fafbfc;
            background-color:#24292e ;
            /* margin-top: 5px; */
            padding: 8px 10px;
            align-items: center;
            justify-content: space-between;

            .git-logo-section{
                display: flex;
                align-items: center;

                img{
                    max-width: 25px;
                    margin-right: 10px;
                }
            }

            a:hover{
                color: #0d74e7;
            }
        }
    }

    .content{
        display: flex;
        flex-direction: column;
        flex: 5;
        overflow-y: auto;
        padding: 20px 60px;

        .img-container{
            display: flex;
            width: 100%;
            justify-content: center;

            img{
                max-width: 55%;
            }
        }

        ul, ol {
            padding-left: 20px;
        }

        /* Apply this CSS to your table */
        table {
            width: 100%;
            border-collapse: collapse;
            font-family: Arial, sans-serif;
            background-color: #ffffff; 
            margin: 20px 0;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            overflow: hidden;
        }

        thead {
            background-color: #00c5b5; /* Darker complement to #B6F7F1 */
            color: white;
        }

        th, td {
            padding: 12px 15px;
            text-align: left;
        }

        th {
            text-transform: uppercase;
            font-size: 14px;
            letter-spacing: 0.05em;
        }

        tbody tr {
            border-bottom: 1px solid #e0f7f5; /* Light border with a subtle contrast */
        }

        tbody tr:hover {
            background-color: #B6F7F1; /* Hover color matches the theme */
        }

        a {
            color: #00a598; /* Link color harmonious with #B6F7F1 */
            text-decoration: none;
            font-weight: bold;
        }

        a:hover {
            text-decoration: underline;
        }
    }
}